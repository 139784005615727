import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Project, ProjectData } from '../Project';

type ProjectListingProps = {
  endpoint: string;
  projects: ProjectData[];
  total: number;
};

export const ProjectListing = ({
  endpoint,
  projects,
  total,
}: ProjectListingProps) => {
  const [items, setItems] = useState(projects);
  const [page, setPage] = useState(1);
  return (
    <InfiniteScroll
      next={async () => {
        try {
          const response = await fetch(`${endpoint}/${page}.json`);
          const data = await response.json();

          setItems((prevState) => [...prevState, ...data.projects]);
          setPage((prevState) => prevState + 1);
        } catch {}
      }}
      hasMore={items.length < total}
      loader={null}
      dataLength={items.length}
    >
      <section className='component-project-listing'>
        {items.map((project) => (
          <Project data={project} key={project.id} />
        ))}
      </section>
    </InfiniteScroll>
  );
};

import { graphql, PageProps } from 'gatsby';
import { useState, useLayoutEffect } from 'react';

import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import { ProjectListing } from '../components/ProjectListing';

import {
  ProjectsTemplateQuery,
  ProjectsTemplateQueryVariables,
} from '../graphql/schema';

type CategoryProps = PageProps<
  ProjectsTemplateQuery,
  ProjectsTemplateQueryVariables & {
    endpoint: string;
    total: number;
  }
>;

const ProjectsTemplate = ({ data, pageContext }: CategoryProps) => {
  const [position, setPosition] = useState(0);

  const onScroll = () => {
    setPosition(window.scrollY);
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <Layout>
      <Seo title='Home' />
      <div className='page-index'>
        <header style={{ transform: `translateY(-${position / 3}px)` }}>
          <div />
          <h1>
            Ventie30 is a communication & design studio based in Reggio Emilia.
            Hub for design, coding, arts and immersive experiences.
          </h1>
          <div className='social'>
            <a href='https://www.instagram.com/ventie30' target='_blank'>
              <svg
                viewBox='0 0 21.017 21.017'
                xmlns='http://www.w3.org/2000/svg'
              >
                <defs>
                  <clipPath id='a'>
                    <rect
                      transform='translate(0)'
                      width='21.017'
                      height='21.017'
                      fill='none'
                      data-name='Rettangolo 18'
                    />
                  </clipPath>
                </defs>
                <g clipPath='url(#a)' data-name='Raggruppa 4'>
                  <path
                    transform='translate(0)'
                    d='M17.264,21.017H3.753A3.753,3.753,0,0,1,0,17.264V3.753A3.753,3.753,0,0,1,3.753,0H17.264a3.753,3.753,0,0,1,3.753,3.753V17.264a3.753,3.753,0,0,1-3.753,3.753'
                    fill='#222221'
                    data-name='Tracciato 3'
                  />
                  <path
                    transform='translate(-5.964 -5.964)'
                    d='M16.473,11.749c1.539,0,1.721.006,2.328.034a3.167,3.167,0,0,1,1.07.2,1.908,1.908,0,0,1,1.094,1.093,3.19,3.19,0,0,1,.2,1.07c.027.608.034.79.034,2.328s-.006,1.721-.034,2.328a3.193,3.193,0,0,1-.2,1.07,1.908,1.908,0,0,1-1.094,1.093,3.19,3.19,0,0,1-1.07.2c-.608.027-.79.034-2.328.034s-1.721-.006-2.328-.034a3.19,3.19,0,0,1-1.07-.2,1.908,1.908,0,0,1-1.094-1.093,3.193,3.193,0,0,1-.2-1.07c-.027-.608-.033-.79-.033-2.328s.006-1.721.033-2.328a3.19,3.19,0,0,1,.2-1.07,1.908,1.908,0,0,1,1.094-1.093,3.167,3.167,0,0,1,1.07-.2c.608-.028.79-.034,2.328-.034m0-1.038c-1.565,0-1.761.007-2.376.035a4.219,4.219,0,0,0-1.4.268A2.943,2.943,0,0,0,11.014,12.7a4.224,4.224,0,0,0-.268,1.4c-.028.615-.035.811-.035,2.376s.007,1.761.035,2.376a4.219,4.219,0,0,0,.268,1.4A2.945,2.945,0,0,0,12.7,21.932a4.232,4.232,0,0,0,1.4.268c.615.028.811.035,2.376.035s1.761-.006,2.376-.035a4.232,4.232,0,0,0,1.4-.268,2.948,2.948,0,0,0,1.685-1.685,4.232,4.232,0,0,0,.268-1.4c.028-.615.035-.811.035-2.376s-.007-1.761-.035-2.376a4.237,4.237,0,0,0-.268-1.4,2.945,2.945,0,0,0-1.685-1.685,4.219,4.219,0,0,0-1.4-.268c-.615-.028-.811-.035-2.376-.035'
                    fill='#fff'
                    data-name='Tracciato 4'
                  />
                  <path
                    transform='translate(-9.487 -9.487)'
                    d='M19.995,17.037A2.959,2.959,0,1,0,22.954,20a2.959,2.959,0,0,0-2.959-2.959m0,4.879A1.921,1.921,0,1,1,21.915,20a1.921,1.921,0,0,1-1.921,1.921'
                    fill='#fff'
                    data-name='Tracciato 5'
                  />
                  <path
                    transform='translate(-16.201 -8.471)'
                    d='M30.476,15.9a.692.692,0,1,1-.691-.691.692.692,0,0,1,.691.691'
                    fill='#fff'
                    data-name='Tracciato 6'
                  />
                </g>
              </svg>
            </a>
            <a href='https://www.behance.net/ventie30' target='_blank'>
              <svg
                data-name='Raggruppa 7'
                viewBox='0 0 21.017 21.017'
                xmlns='http://www.w3.org/2000/svg'
              >
                <defs>
                  <clipPath id='a'>
                    <rect
                      width='21.017'
                      height='21.017'
                      fill='none'
                      data-name='Rettangolo 20'
                    />
                  </clipPath>
                </defs>
                <g
                  transform='translate(0)'
                  clipPath='url(#a)'
                  data-name='Raggruppa 6'
                >
                  <path
                    transform='translate(0)'
                    d='M17.264,21.017H3.753A3.753,3.753,0,0,1,0,17.264V3.753A3.753,3.753,0,0,1,3.753,0H17.264a3.753,3.753,0,0,1,3.753,3.753V17.264a3.753,3.753,0,0,1-3.753,3.753'
                    fill='#222221'
                    data-name='Tracciato 7'
                  />
                  <path
                    transform='translate(-5.761 -8.465)'
                    d='M14.7,22.46a2.019,2.019,0,0,0,.831-.369,2.041,2.041,0,0,0,.521-.579,2.154,2.154,0,0,0,.327-1.174,1.95,1.95,0,0,0-.286-1.078,1.642,1.642,0,0,0-.85-.649,1.755,1.755,0,0,0,.561-.419,1.64,1.64,0,0,0,.341-1.083,1.87,1.87,0,0,0-.338-1.123A2.257,2.257,0,0,0,13.9,15.2H10.345V22.56h3.316a5.069,5.069,0,0,0,1.04-.1m-2.888-5.98h1.6a2.753,2.753,0,0,1,.869.115.658.658,0,0,1,.4.678.7.7,0,0,1-.3.647,1.479,1.479,0,0,1-.778.182H11.813Zm0,4.8V19.321h1.811a1.85,1.85,0,0,1,.736.125.789.789,0,0,1,.468.788.927.927,0,0,1-.483.919,1.737,1.737,0,0,1-.745.129Z'
                    fill='#fff'
                    data-name='Tracciato 8'
                  />
                  <path
                    transform='translate(-13.867 -10.693)'
                    d='M25.733,24.3a2.964,2.964,0,0,0,1.916.67A2.64,2.64,0,0,0,29.7,24.18a1.771,1.771,0,0,0,.528-.983H28.772a1.181,1.181,0,0,1-.293.374,1.2,1.2,0,0,1-.79.25,1.444,1.444,0,0,1-.784-.2,1.306,1.306,0,0,1-.569-1.148H30.3a7.024,7.024,0,0,0-.047-1.08,2.669,2.669,0,0,0-.433-1.125,2.211,2.211,0,0,0-.941-.805A3.1,3.1,0,0,0,27.6,19.2a2.622,2.622,0,0,0-1.945.754,2.944,2.944,0,0,0-.75,2.168,2.64,2.64,0,0,0,.83,2.179m1.016-3.617a1.131,1.131,0,0,1,.849-.311,1.25,1.25,0,0,1,.844.293,1.187,1.187,0,0,1,.379.86H26.371a1.479,1.479,0,0,1,.379-.842'
                    fill='#fff'
                    data-name='Tracciato 9'
                  />
                  <rect
                    transform='translate(12.039 7.16)'
                    width='3.198'
                    height='.743'
                    fill='#fff'
                    data-name='Rettangolo 19'
                  />
                </g>
              </svg>
            </a>
          </div>
        </header>

        <ProjectListing
          endpoint={pageContext.endpoint}
          /*   @ts-ignore */
          projects={data.projects.nodes}
          total={pageContext.total}
        />
      </div>
    </Layout>
  );
};

export default ProjectsTemplate;

export const query = graphql`
  fragment ProjectTemplateImage on STRAPI__MEDIA {
    id
    localFile {
      publicURL
    }
    mime
  }

  query ProjectsTemplate($id: String!, $limit: Int!) {
    projects: allStrapiProject(
      filter: {
        locale: { eq: "en" }
        categories: { elemMatch: { id: { eq: $id } } }
      }
      limit: $limit
      sort: { fields: [createdAt], order: DESC }
    ) {
      nodes {
        categories {
          id
          slug
          title
        }
        featuredImage: featured_image {
          ...ProjectTemplateImage
        }
        gallery {
          ...ProjectTemplateImage
        }
        id
        slug
        title
      }
    }
  }
`;
